var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.variant === 'primary')?_c('Select',{attrs:{"value":_vm.value,"options":_vm.presenceStatusOptions,"placeholder":"Select status","is-mini":"","is-transparent":"","is-dark-border":"","is-disabled":_vm.isDisabled},on:{"input":_vm.onChange},nativeOn:{"click":function($event){$event.stopPropagation();}}}):_vm._e(),(_vm.variant === 'secondary')?_c('DropdownStatus',{attrs:{"options":_vm.presenceStatusOptions,"placeholder":"Select status","value":_vm.value,"variant":_vm.value === _vm.PresenceStatusEnum.NO
        ? 'danger'
        : _vm.value === _vm.PresenceStatusEnum.PARTIALLY
        ? 'warning'
        : _vm.value === _vm.PresenceStatusEnum.FULLY
        ? 'success'
        : 'default'},on:{"setSelectedOption":_vm.onChange}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }