var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bookings-table-wrapper"},[_c('vue-good-table',{staticClass:"default-table",attrs:{"columns":_vm.tableColumns,"rows":_vm.bookings,"styleClass":"bookings-table"},on:{"on-row-click":_vm.handleRowClick,"on-sort-change":_vm.onSort},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'presenceStatus')?_c('div',{staticClass:"bookings-table__dropdown-wrapper"},[_c('BookingStatus',{attrs:{"variant":"secondary","value":props.formattedRow[props.column.field]
              ? props.formattedRow[props.column.field]
              : _vm.isPresenceStatusAvailable(props.row.datetime)
              ? _vm.PresenceStatusEnum.NO
              : null},on:{"change":(val) => _vm.handleChangePresenceStatus(val, props.row.id)}})],1):(
          props.column.field === 'settings' &&
          _vm.getMenuOptions(props.row).length
        )?_c('div',{staticClass:"bookings-table__settings"},_vm._l((_vm.getMenuOptions(props.row)),function(item,index){return _c('div',{key:index,attrs:{"title":item.name}},[_c('Icon',{attrs:{"name":item.icon,"is-clickable":"","size":24,"color":item.iconColor || 'secondary-400'},on:{"click":function($event){return _vm.handleMenuSelect(item, props.row)}}})],1)}),0):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"table-actions-bottom",fn:function(){return [_vm._t("footer")]},proxy:true},{key:"emptystate",fn:function(){return [_vm._v(" "+_vm._s("")+" ")]},proxy:true},{key:"table-column",fn:function(props){return [(props.column.field === 'settings')?_c('Icon',{attrs:{"name":"gear","is-clickable":"","size":24,"color":"secondary-500"},on:{"click":function($event){return _vm.$modal.show(_vm.bookingTableSettingsModalName)}}}):_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")])]}}],null,true)}),_c('BookingsTableSettingsModal',{attrs:{"name":_vm.bookingTableSettingsModalName,"value":(_vm.venueUserJunction && _vm.venueUserJunction.bookingsTableColumns) ||
      _vm.defaultBookingsTableColumns,"is-submitting":_vm.isTableSettingsSubmitting},on:{"submit":_vm.saveTableSettings}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }