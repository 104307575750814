<template>
  <div
    :class="[
      'dropdown',
      `m-${currentVariant ?? variant}`,
      isBottomSectionToggled ? 'dropdown-shown' : '',
    ]"
    @click="toggleDropdown"
  >
    <span class="text">
      {{
        placeholderText && !selectedOption
          ? placeholderText
          : selectedOption.name
      }}</span
    >
    <span
      :class="[
        'dropdown__icon',
        isBottomSectionToggled ? 'dropdown__icon--active' : '',
      ]"
    >
      <Icon
        name="chevron-down"
        :size="16"
        :color="currentVariant === 'default' ? 'secondary-500' : 'white'"
      />
    </span>
    <ul
      v-if="isBottomSectionToggled"
      :class="[
        'options',
        `m-${currentVariant ?? variant}`,
        isBottomSectionToggled ? 'options--active' : '',
      ]"
    >
      <li
        v-for="(option, idx) in configOptions"
        :key="idx"
        :class="['option', `m-${option.variant}`]"
        @click="setCurrentSelectedOption($event, option)"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DropdownPresenceStatus",
  data() {
    return {
      isBottomSectionToggled: false,
      configOptions: [],
      selectedOption: null,
      placeholderText: "",
    };
  },
  components: {},
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Placeholder",
    },
    value: {
      default: "",
    },
    selectedValue: {
      default: null,
    },
    variant: {
      type: String,
      default: "default",
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.selectedOption = this.options.find(
          (option) => option.value === val
        );
      },
    },
  },
  computed: {
    currentVariant() {
      return this.selectedOption?.value === "no"
        ? "danger"
        : this.selectedOption?.value === "partially"
        ? "warning"
        : this.selectedOption?.value === "fully"
        ? "success"
        : "default";
    },
  },
  methods: {
    toggleDropdown(event) {
      event.stopPropagation();
      this.isBottomSectionToggled = !this.isBottomSectionToggled;
    },
    setCurrentSelectedOption(event, option) {
      event.stopPropagation();
      this.selectedOption = option;
      this.isBottomSectionToggled = false;
      this.$emit("setSelectedOption", option.value);
    },
    setConfigData() {
      this.configOptions = this.options;
      this.placeholderText = this.placeholder;

      if (this.selectedValue) {
        this.selectedOption = this.selectedValue;
        this.$emit("setSelectedOption", this.selectedOption, this.value);
      }
    },
    closeByEscape(event) {
      if (event.key === "Escape") {
        this.isBottomSectionToggled = false;
      }
    },
  },
  created() {
    this.setConfigData();
  },
  mounted() {
    window.addEventListener("keydown", this.closeByEscape);
  },
  destroyed() {
    window.removeEventListener("keydown", this.closeByEscape);
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  display: flex;
  align-items: center;
  border-radius: 3px;
  min-height: 16px;
  padding: 0 8px;
  position: relative;
  cursor: pointer;
  color: #fff;
  user-select: none;

  &.m-default {
    color: var(--color-secondary-500);
    background: transparent;
    border: 1px solid var(--color-secondary-500);
  }

  &.m-success {
    background: #3fab77;

    &:hover {
      background: #4be69b;
      transition: 0.4s all;
      .text,
      i {
        color: #fff;
      }
    }
  }

  &.m-warning {
    background: #f8d267;

    &:hover {
      background: #eacc77;
      transition: 0.4s all;

      .text,
      i {
        color: #fff;
      }
    }
  }

  &.m-danger {
    background: #ff7171;

    &:hover {
      background: #e37070;
      transition: 0.4s all;

      .text,
      i {
        color: #fff;
      }
    }
  }

  &__icon {
    display: flex;
    transition: 0.4s ease;

    &--active {
      transform: rotate(180deg);
    }
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .options {
    display: flex;
    flex-direction: column;
    width: 100%;
    // margin-bottom: calc(-1 * var(--options-height) - 3px);
    position: absolute;
    left: 0px;
    padding: 0;
    top: calc(16px);
    z-index: 1;
    border-radius: 3px;
    transition: 0.4s ease;
    box-shadow: $box-shadow-small;
    border: 1px solid $secondary-400;
    z-index: 1;
    background-color: $white;

    &--active {
      border-radius: 0 0 4px 4px;
    }

    .option {
      width: auto;
      padding: 0 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $secondary-500;
      transition: 0.3s;
      text-align: center;
      list-style: none;

      &:not(:last-child) {
        border-bottom: 1px solid $secondary-400;
      }

      &.m-success {
        &:hover {
          color: #fff;
          background: #4be69b;
        }
      }

      &.m-warning {
        &:hover {
          color: #fff;
          background: #eacc77;
        }
      }

      &.m-danger {
        &:hover {
          color: #fff;
          background: #e37070;
        }
      }
    }

    .option:last-child {
      border-radius: 0 0 4px 4px;
    }
  }
  .shown {
    max-height: 999px;
    transition: all 1s linear;
  }
}
.dropdown-shown {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
