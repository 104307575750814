<template>
  <div class="bookings-table-wrapper">
    <!-- <div class="scroll-action">
      <Icon
        class="scroll-action__button"
        name="arrow-left"
        is-clickable
        :size="24"
        color="secondary-500"
      />
      <Icon
        class="scroll-action__button"
        name="arrow-right"
        is-clickable
        :size="24"
        color="secondary-500"
      />
    </div> -->
    <vue-good-table
      :columns="tableColumns"
      :rows="bookings"
      class="default-table"
      styleClass="bookings-table"
      @on-row-click="handleRowClick"
      @on-sort-change="onSort"
    >
      <template #table-row="props">
        <div
          class="bookings-table__dropdown-wrapper"
          v-if="props.column.field === 'presenceStatus'"
        >
          <BookingStatus
            variant="secondary"
            :value="
              props.formattedRow[props.column.field]
                ? props.formattedRow[props.column.field]
                : isPresenceStatusAvailable(props.row.datetime)
                ? PresenceStatusEnum.NO
                : null
            "
            @change="(val) => handleChangePresenceStatus(val, props.row.id)"
          />
        </div>
        <div
          v-else-if="
            props.column.field === 'settings' &&
            getMenuOptions(props.row).length
          "
          class="bookings-table__settings"
        >
          <div
            v-for="(item, index) in getMenuOptions(props.row)"
            :key="index"
            :title="item.name"
          >
            <Icon
              :name="item.icon"
              is-clickable
              :size="24"
              :color="item.iconColor || 'secondary-400'"
              @click="handleMenuSelect(item, props.row)"
            />
          </div>
        </div>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template #table-actions-bottom>
        <slot name="footer" />
      </template>
      <template #emptystate>
        {{ "" }}
      </template>
      <template slot="table-column" slot-scope="props">
        <Icon
          v-if="props.column.field === 'settings'"
          name="gear"
          is-clickable
          :size="24"
          color="secondary-500"
          @click="$modal.show(bookingTableSettingsModalName)"
        />
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>
    </vue-good-table>
    <BookingsTableSettingsModal
      :name="bookingTableSettingsModalName"
      :value="
        (venueUserJunction && venueUserJunction.bookingsTableColumns) ||
        defaultBookingsTableColumns
      "
      :is-submitting="isTableSettingsSubmitting"
      @submit="saveTableSettings"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import moment from "moment";
import { PresenceStatusOptions } from "@/helpers/const";
import {
  BookingTableSortFieldEnum,
  PresenceStatusEnum,
  SortDirectionEnum,
} from "@/helpers/enums";
import BookingStatus from "@/components/bookings/BookingStatus";
import {
  BOOKING_MENU_OPTION_VALUES,
  DEFAULT_BOOKINGS_TABLE_COLUMNS,
} from "@/helpers/mocks";
import alert from "@/plugins/alert";
import BookingsTableSettingsModal from "@/components/BookingsTable/BookingsTableSettingsModal.vue";

export default {
  name: "BookingsTable",
  components: {
    BookingsTableSettingsModal,
    BookingStatus,
  },
  props: {
    bookings: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true,
    },
    hasSettingsColumns: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PresenceStatusEnum,
      selectedStatus: null,
      presenceStatusOptions: [...PresenceStatusOptions],
      bookingTableSettingsModalName: "bookingTableSettingsModal",
      isTableSettingsSubmitting: false,
      defaultBookingsTableColumns: [...DEFAULT_BOOKINGS_TABLE_COLUMNS],
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.products.list,
      venueUserJunction: (state) => state.users.venueUserJunction,
      selectedProduct: (state) => state.products.selectedProduct,
      activeSortField: (state) => state.bookings.activeSortField,
      activeSortDirection: (state) => state.bookings.activeSortDirection,
    }),
    ...mapGetters({
      checkPermission: "users/checkPermission",
    }),
    tableColumns() {
      return [
        ...this.columns,
        this.hasSettingsColumns && {
          field: "settings",
          sortable: false,
        },
      ]
        .filter(Boolean)
        .map((column) => {
          return {
            ...this.getColumnSettings(column),
            ...column,
          };
        });
    },
  },
  methods: {
    ...mapActions({
      updateBooking: "bookings/updateBooking",
      updateVenueUserJunction: "users/updateVenueUserJunction",
    }),
    ...mapMutations({
      SET_LIST: "bookings/SET_LIST",
      SET_ACTIVE_SORT_FIELD: "bookings/SET_ACTIVE_SORT_FIELD",
      SET_ACTIVE_SORT_DIRECTION: "bookings/SET_ACTIVE_SORT_DIRECTION",
    }),
    getColumnSettings(column) {
      return {
        thClass: [
          "bookings-table__head",
          column.sortable &&
            column.field === this.activeSortField &&
            `m-${this.activeSortDirection}`,
          column.field === "settings" && "m-fixed-right",
        ]
          .filter(Boolean)
          .join(" "),
        tdClass: [
          "bookings-table__cell",
          column.field === "settings" && "m-fixed-right",
        ]
          .filter(Boolean)
          .join(" "),
        sortable: false,
      };
    },
    handleRowClick({ row }) {
      this.$emit("on-row-click", row.id);
    },
    async handleChangePresenceStatus(status, bookingId) {
      const index = this.bookings.findIndex(
        (booking) => booking.id === bookingId
      );

      if (status && bookingId) {
        this.bookings[index].presenceStatus = status;
        this.SET_LIST([...this.bookings]);
        try {
          await this.updateBooking({
            id: bookingId,
            data: {
              presenceStatus: status,
            },
          });
        } catch (ex) {
          this.SET_LIST([...this.bookings]);
          await alert.open({
            message: ex.message,
            variant: "danger",
          });
        }
      }
    },
    isPresenceStatusAvailable(datetime) {
      return moment
        .utc()
        .isSameOrAfter(moment.utc(datetime).add(-90, "minutes"));
    },
    onSort(params) {
      const [column] = params;
      const { field } = column || {};
      if (field) {
        if (field === this.activeSortField) {
          this.SET_ACTIVE_SORT_DIRECTION(
            this.activeSortDirection === SortDirectionEnum.DESC
              ? SortDirectionEnum.ASC
              : SortDirectionEnum.DESC
          );
        } else {
          this.SET_ACTIVE_SORT_FIELD(field);
          this.SET_ACTIVE_SORT_DIRECTION(
            field === BookingTableSortFieldEnum.CREATION_DATE
              ? SortDirectionEnum.DESC
              : SortDirectionEnum.ASC
          );
        }
        this.$emit("sort", field);
      }
    },
    getMenuOptions(booking) {
      return [
        // {
        //   value: BOOKING_MENU_OPTION_VALUES.OPEN,
        //   name: "Open details",
        //   icon: "eye",
        // },
        this.checkPermission("bookings.save") && {
          value: BOOKING_MENU_OPTION_VALUES.FAVORITE,
          name: booking.isSaved ? "Remove from saved" : "Add to saved",
          icon: booking.isSaved ? "favorite-filled" : "favorite",
          iconColor: booking.isSaved ? "primary" : "secondary-400",
        },
        this.checkPermission("signatures.create") && {
          value: BOOKING_MENU_OPTION_VALUES.SIGN,
          name: "Sign a waiver",
          icon: "laptop-pencil",
        },
      ].filter(Boolean);
    },
    async saveTableSettings(data) {
      try {
        this.isTableSettingsSubmitting = true;
        await this.updateVenueUserJunction({ bookingsTableColumns: data });
        this.$modal.hide(this.bookingTableSettingsModalName);
      } finally {
        this.isTableSettingsSubmitting = false;
      }
    },
    async handleMenuSelect(option, booking) {
      if (option.value === BOOKING_MENU_OPTION_VALUES.OPEN) {
        return this.$router.push({
          name: "BookingDetail",
          params: {
            id: booking.id,
          },
        });
      } else if (option.value === BOOKING_MENU_OPTION_VALUES.SIGN) {
        return this.$router.push({
          name: "BookingSignaturesSign",
          params: {
            id: booking.id,
          },
        });
      } else if (option.value === BOOKING_MENU_OPTION_VALUES.FAVORITE) {
        const isSaved = !booking.isSaved;
        try {
          await this.updateBooking({
            id: booking.id,
            data: {
              isSaved,
            },
            replaceBooking: true,
          });
          alert.open({
            message: this.$t(isSaved ? "Added to Saved" : "Removed from Saved"),
            variant: "secondary",
          });
        } catch (e) {
          console.log(e);
          alert.open({
            message: this.$t(
              isSaved ? "Failed to add to Saved" : "Failed to remove from Saved"
            ),
            variant: "danger",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/table.scss";

.bookings-table-wrapper {
  position: relative;
}

.scroll-action {
  position: absolute;
  right: 76px;
  padding: 16.6px 9.5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  z-index: 10;

  &__button {
    border-radius: 30px;

    &:hover {
      background-color: #e6e6e7;
    }
  }
}

.bookings-table {
  &__dropdown-wrapper {
    max-width: 100px;
  }

  &__head {
    background-color: $secondary-300;
    &.m-fixed-right {
      position: sticky !important;
      top: 0;
      right: 0;
    }
  }

  &__cell {
    background-color: $white;
    &.m-fixed-right {
      position: sticky;
      top: 0;
      right: 0;
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
}
</style>
