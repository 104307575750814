import moment from "moment/moment";
import {
  getBookingPrice,
  getMinutes,
  getTimezoneOffsetHours,
} from "@/helpers/utils";
import store from "@/store";

export const getBookingsTableColumns = (bookingsTableColumns) => {
  const timezone = store.state.venues.selectedVenue?.timezone;
  const ALL_COLUMNS = [
    {
      id: "presenceStatus",
      label: "Status",
      field: "presenceStatus",
      formatFn: (presenceStatus) => presenceStatus || null,
    },
    {
      id: "createdAt",
      label: "Date Created",
      field: "createdAt",
      formatFn: (createdAt) => {
        const createdAtMoment = moment.utc(createdAt.seconds, "X");
        return createdAtMoment
          .add(getTimezoneOffsetHours(timezone), "h")
          .format("MMM D, YYYY");
      },
      sortable: true,
      sortFn: () => {},
    },
    {
      id: "date",
      label: "Date of Event",
      field: "date",

      formatFn: (date) => {
        const dateMoment = moment.utc(date.seconds || date._seconds, "X");
        return dateMoment
          .add(getTimezoneOffsetHours(timezone), "h")
          .format("MMM D, YYYY");
      },
      sortable: true,
      sortFn: () => {},
    },
    {
      id: "customerFullName",
      label: "Name",
      field: "reservationInfo.fullName",
    },
    { id: "customerEmail", label: "Email", field: "reservationInfo.email" },
    {
      id: "customerPhone",
      label: "Phone",
      field: "reservationInfo.phone",
    },
    {
      id: "lanes",
      label: "Lanes",
      field: (booking) => Object.keys(booking.resources)?.length || 0,
    },
    {
      id: "duration",
      label: "Duration",
      field: (booking) =>
        booking.slots.reduce((current, slot) => {
          return getMinutes(slot.to) - getMinutes(slot.from);
        }, 0),
    },
    {
      id: "regCode",
      label: "Reg.Code",
      field: "registrationCode",
    },
    {
      id: "waivers",
      label: "Waivers",
      field: (booking) =>
        `${booking.numberOfSignedWaivers ?? 0}/${booking.playersCount}`,
    },
    {
      id: "paid",
      label: "Paid",
      field: (booking) => {
        const price = getBookingPrice(booking?.checkoutInfo);
        return (price?.paid || 0) / 100;
      },
    },
    {
      id: "paymentDue",
      label: "Payment Due",
      field: (booking) => {
        const price = getBookingPrice(booking?.checkoutInfo);
        if (price) {
          return (
            ((price.total || 0) -
              ((price?.paid || 0) - (price?.refunded || 0))) /
            100
          );
        }
        return 0;
      },
    },
  ];
  return bookingsTableColumns
    .filter((columnConfig) => !columnConfig.isHidden)
    .map((columnConfig) => {
      const columnDefinition = ALL_COLUMNS.find(
        (item) => item.id === columnConfig.id
      );
      const label = columnConfig?.label || columnDefinition.label;
      return { ...columnDefinition, label };
    });
};
