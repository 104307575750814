var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'change-view-button',
    _vm.variant === _vm.BookingPageViewEnum.TABLE ? 'space-3' : '',
  ],on:{"click":_vm.handleSelectedView}},_vm._l((_vm.countItems),function(item){return _c('span',{key:item,class:[
      'change-view-button__item',
      _vm.variant === _vm.BookingPageViewEnum.DETAILED
        ? 'm-big'
        : _vm.variant === _vm.BookingPageViewEnum.COMPACT
        ? 'm-middle'
        : 'm-small',
    ]})}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }