<template>
  <div>
    <Select
      v-if="variant === 'primary'"
      :value="value"
      :options="presenceStatusOptions"
      placeholder="Select status"
      is-mini
      is-transparent
      is-dark-border
      :is-disabled="isDisabled"
      @click.native.stop
      @input="onChange"
    />
    <DropdownStatus
      v-if="variant === 'secondary'"
      :options="presenceStatusOptions"
      placeholder="Select status"
      :value="value"
      :variant="
        value === PresenceStatusEnum.NO
          ? 'danger'
          : value === PresenceStatusEnum.PARTIALLY
          ? 'warning'
          : value === PresenceStatusEnum.FULLY
          ? 'success'
          : 'default'
      "
      @setSelectedOption="onChange"
    />
  </div>
</template>

<script>
import { PresenceStatusEnum } from "@/helpers/enums";
import { PresenceStatusOptions } from "@/helpers/const";
import DropdownStatus from "@/components/DropdownPresenceStatus/DropdownPresenceStatus.vue";

export default {
  name: "BookingStatus",
  components: { DropdownStatus },
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    value: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PresenceStatusEnum,
      presenceStatusOptions: [...PresenceStatusOptions],
    };
  },
  methods: {
    onChange(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
