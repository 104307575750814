<template>
  <div :class="['booking-card', `m-${theme}`]">
    <div class="content">
      <div class="header">
        <span class="title m-overflowed">
          {{ booking.reservationInfo.firstName }}
          {{ booking.reservationInfo.lastName }}
        </span>
        <div v-if="isDetailed" class="booking-card__people">
          <Icon
            class="booking-card__peopleIcon"
            name="people"
            color="secondary-400"
          />
          {{ personsText }}
        </div>
        <div v-else-if="productName" class="booking-card__people m-overflowed">
          {{ productName }}
        </div>
        <div v-if="booking.isWarning" class="new-label booking-card__time">
          WARNING
        </div>
        <div v-else-if="booking.isWalkIn" class="new-label booking-card__time">
          WALK-IN
        </div>
        <div
          v-else-if="formattedTimeAgo === 'new'"
          class="new-label booking-card__time"
        >
          NEW
        </div>
        <span v-else class="secondary-text booking-card__time">
          {{ formattedTimeAgo }}
        </span>
      </div>
      <div
        class="booking-card__note"
        v-if="booking.reservationInfo && booking.reservationInfo.note"
      >
        {{ booking.reservationInfo.note }}
      </div>
      <div class="booking-card__commentWrapper">
        <div v-if="isDetailed">
          {{ booking.reservationInfo.phone }}
        </div>
        <div
          v-else
          :class="[
            'datetime',
            { 'm-primary': calendarIconColor === 'primary' },
          ]"
        >
          <Icon name="calendar" :color="calendarIconColor" />
          <span>
            {{ formattedDate }} /
            <span class="time">{{ formattedTime }}</span>
          </span>
        </div>
        <Guard
          permission="bookings.changePresenceStatus"
          v-slot="{ isAvailable }"
        >
          <BookingStatus
            :is-disabled="!isAvailable"
            :value="
              booking.presenceStatus
                ? booking.presenceStatus
                : isStartBookingTime
                ? PresenceStatusEnum.NO
                : null
            "
            @change="handleChangePresenceStatus"
          />
        </Guard>
      </div>
      <div class="booking-card__reg-code">
        Reg. code: {{ booking.registrationCode }}
      </div>
    </div>
    <div class="statuses-row">
      <span v-if="isWaitingForPayment" :class="['secondary-text']">
        {{ $t("Waiting for payment") }}...
      </span>
      <template v-else>
        <template v-for="(status, index) in statuses">
          <div
            v-if="index !== 0"
            :key="`statusDivider-${index}`"
            class="statuses-row__divider"
          />
          <span :key="`statusLabel-${index}`" :class="status.classes">
            {{ status.label }}
          </span>
        </template>
      </template>
      <div class="icon-actions">
        <Guard permission="signatures.create" v-slot="{ isAvailable }">
          <Icon
            v-if="isAvailable && booking.activeWaiver"
            name="laptop-pencil"
            color="secondary-400"
            is-clickable
            @click.stop="goToSigningWaiver"
          />
        </Guard>
        <Icon
          v-if="booking.isSaved"
          name="favorite-filled"
          color="primary"
          is-clickable
          @click.stop="$emit('favorite-clicked', false)"
        />
        <Icon
          v-else
          name="favorite"
          color="secondary-400"
          is-clickable
          @click.stop="$emit('favorite-clicked', true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  getFormattedBookingTime,
  getFormattedSlots,
  getTimeDifference,
} from "@/helpers/utils";
import { BookingStatusEnum, PresenceStatusEnum } from "@/helpers/enums";
import { getBookingPrice } from "@/helpers/utils";
import { PresenceStatusOptions } from "@/helpers/const";
import { mapActions, mapState } from "vuex";
import alert from "@/plugins/alert";
import Guard from "@/components/common/Guard";
import BookingStatus from "@/components/bookings/BookingStatus";

export default {
  name: "BookingCard",
  components: { Guard, BookingStatus },
  props: {
    booking: {
      type: Object,
      required: true,
    },
    duration: {
      type: [Number, String],
      default: 60,
    },
    productName: {
      type: String,
      default: "",
    },
    timezone: {
      type: String,
      required: true,
    },
    isDetailed: {
      type: Boolean,
      default: false,
    },
    breakTime: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      PresenceStatusEnum,
      presenceStatusOptions: [...PresenceStatusOptions],
    };
  },
  computed: {
    ...mapState({
      country: (state) => state.venues.selectedVenue?.country,
    }),
    personsText() {
      return !this.booking?.isSpecifyGroupSize && this.booking.selectedGroup
        ? `${this.booking.selectedGroup.minimum}-${this.booking.selectedGroup.maximum} persons`
        : `${this.booking.playersCount} person${
            this.booking.playersCount > 1 ? "s" : ""
          }`;
    },
    price() {
      if (!this.booking?.checkoutInfo) return {};

      return getBookingPrice(this.booking?.checkoutInfo);
    },
    paidAmount() {
      if (!this.price.paid) return false;

      return this.price.paid / 100;
    },
    paymentDueAmount() {
      if (!this.price.total) return false;

      return (
        (this.price.total -
          ((this.price.paid || 0) - (this.price.refunded || 0))) /
        100
      );
    },
    refundedAmount() {
      const amount = this.price.refunded;
      if (!amount) return false;

      return amount / 100;
    },
    formattedDate() {
      return `${!this.isUpcoming ? "Ended " : ""}${getFormattedBookingTime(
        this.booking
      )}`;
    },
    formattedTime() {
      return getFormattedSlots(this.booking, this.timezone);
    },
    isUpcoming() {
      return (
        (this.booking.date.seconds || this.booking.date._seconds) * 1000 >=
        moment.utc().valueOf()
      );
    },
    isStartBookingTime() {
      return (
        moment.utc().valueOf() >=
        ((this.booking.date.seconds || this.booking.date._seconds) - 5400) *
          1000
      );
    },
    isTodayOrPast() {
      return (
        (this.booking.date.seconds || this.booking.date._seconds) * 1000 <=
        moment.utc().endOf("date").valueOf()
      );
    },
    isCanceled() {
      return this.booking.status === BookingStatusEnum.CANCELED;
    },
    isAbandon() {
      return this.booking.status === BookingStatusEnum.ABANDONED;
    },
    isWaitingForPayment() {
      return this.booking.status === BookingStatusEnum.WAIT_FOR_PAYMENT;
    },
    theme() {
      if (this.isAbandon) return "gray";
      if (!this.booking.presenceStatus && this.isStartBookingTime) return "red";

      return this.booking.presenceStatus === PresenceStatusEnum.FULLY
        ? "green"
        : this.booking.presenceStatus === PresenceStatusEnum.PARTIALLY
        ? "yellow"
        : this.booking.presenceStatus === PresenceStatusEnum.NO
        ? "red"
        : "white";
    },
    paidStatusTheme() {
      return this.booking.status === BookingStatusEnum.CANCELED
        ? "black"
        : "green";
    },
    calendarIconColor() {
      return !this.isUpcoming || this.isCanceled ? "secondary-400" : "primary";
    },
    formattedTimeAgo() {
      return getTimeDifference({
        current: moment.utc().valueOf(),
        previous:
          (this.booking.createdAt.seconds || this.booking.createdAt._seconds) *
          1000,
      });
    },
    statuses() {
      return [
        {
          label: "Canceled",
          classes: ["secondary-text", "m-red"],
          isVisible: this.isCanceled,
        },
        {
          label: "Abandoned",
          classes: ["secondary-text"],
          isVisible: this.isAbandon,
        },
        {
          label: `${this.$t("Paid")} ${this.formatAmount(this.paidAmount)}`,
          classes: ["secondary-text", `m-${this.paidStatusTheme}`],
          isVisible: !!this.paidAmount,
        },
        {
          label: `Payment due ${this.formatAmount(this.paymentDueAmount)}`,
          classes: ["secondary-text", "m-red"],
          isVisible: !this.isCanceled && this.paymentDueAmount > 0,
        },
        {
          label: `${this.$t("Refunded")}${
            this.paidAmount !== this.refundedAmount
              ? ` ${this.formatAmount(this.refundedAmount)}`
              : ""
          }`,
          classes: ["secondary-text", "m-black"],
          isVisible: !!this.refundedAmount,
        },
        {
          label: `${this.booking.numberOfSignedWaivers}/${this.booking.playersCount} waivers signed`,
          classes: [
            "secondary-text",
            `m-${
              this.booking.numberOfSignedWaivers <= 0
                ? "red"
                : this.booking.numberOfSignedWaivers >=
                  this.booking.playersCount
                ? "green"
                : "yellow"
            }`,
          ],
          isVisible: this.booking.numberOfSignedWaivers !== undefined,
        },
      ].filter((status) => status.isVisible);
    },
  },
  methods: {
    ...mapActions({
      updateBooking: "bookings/updateBooking",
    }),
    formatAmount(amount) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
      }).format(Math.abs(amount));
    },
    async handleChangePresenceStatus(newPresenceStatus) {
      if (newPresenceStatus !== this.booking.presenceStatus) {
        const oldStatus = this.booking.presenceStatus;
        this.$set(this.booking, "presenceStatus", newPresenceStatus);
        try {
          await this.updateBooking({
            id: this.booking.id,
            data: {
              presenceStatus: newPresenceStatus,
            },
          });
        } catch (ex) {
          this.booking.presenceStatus = oldStatus;
          await alert.open({
            message: ex.message,
            variant: "danger",
          });
        }
      }
    },
    goToSigningWaiver() {
      this.$router.push({
        name: "BookingSignaturesSign",
        params: {
          id: this.booking.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.booking-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: $box-shadow-small;
  border: 2px solid $secondary-400;
  min-height: 140px;
  cursor: pointer;
  transition: 0.3s;
  height: 100%;

  &.m-green {
    background-color: rgba(63, 171, 119, 0.1);
    border-color: $success;
  }

  &.m-red {
    background-color: rgba(255, 113, 113, 0.1);
    border-color: $danger;
  }

  &.m-yellow {
    background-color: rgba(213, 157, 0, 0.1);
    border-color: $yellow;
  }

  &.m-gray {
    background-color: $secondary-200;
    border-color: $secondary-600;
  }

  &__people {
    white-space: nowrap;
    margin-right: 12px;
    display: flex;
    align-items: center;
    margin-left: 16px;
    color: $secondary-400;
  }

  &__peopleIcon {
    margin-right: 6px;
  }

  &__time {
    margin-left: auto;
  }

  &__commentWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $secondary-400;
  }

  &__reg-code {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: $secondary-400;
  }

  &__note {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: $secondary-400;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .header {
      display: flex;
      align-items: center;

      .title {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: $secondary-500;
      }
    }
  }

  .datetime {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    line-height: 20px;
    color: $secondary-400;
    margin-right: 24px;

    &.m-primary {
      color: $primary;
    }

    .time {
      font-weight: 600;
    }
  }

  .statuses-row {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;

    &__divider {
      width: 5px;
      height: 5px;
      background-color: $secondary-400;
      border-radius: 100%;
    }
  }

  .icon-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .new-label {
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: $secondary-500;
    padding: 4px 16px;
    background-color: $yellow;
    border: 2px solid $yellow;
    border-radius: 0 8px;
    margin-top: -24px;
    margin-right: -18px;
  }

  .secondary-text {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: $secondary-400;

    &.m-green {
      color: $success;
    }

    &.m-red {
      color: $danger;
    }

    &.m-yellow {
      color: #d59d00;
    }

    &.m-black {
      color: $secondary-500;
    }
  }

  &.purple {
    background-color: $purple;
    color: $white;
    box-shadow: none;

    .datetime {
      color: $white;
    }

    .secondary-text {
      color: $white;
    }
  }

  .m-overflowed {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 8px;
    display: inline-block;
  }
}
</style>
