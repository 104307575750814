<template>
  <CustomModal
    :name="modalName"
    isScrollable
    classes="bookingTableSettingsModal"
    width="90%"
    @before-close="reset"
    @before-open="reset"
  >
    <div class="bookingTableSettingsModal__close">
      <Icon name="close" is-clickable color="secondary-500" @click="close" />
    </div>
    <template v-slot:header>
      <Title class="bookingTableSettingsModal__title">
        Configure the table
      </Title>
    </template>
    <Form class="bookingTableSettingsModal__form" v-slot="{}">
      <Draggable v-model="innerValue" handle=".drag-handler" class="mb-48">
        <div
          v-for="column in innerValue"
          :key="column.id"
          class="bookingTableSettingsModal__form__row"
        >
          <div class="bookingTableSettingsModal__form__row__block">
            <div class="drag-handler">
              <Icon name="drag-handler" color="secondary-400" />
            </div>
            <span
              v-if="!editLabelIsActiveMap[column.id]"
              :class="[
                'bookingTableSettingsModal__form__row__name',
                {
                  'm-hidden': column.isHidden,
                },
              ]"
            >
              {{ editLabelMap[column.id] }}
            </span>
            <input
              v-else
              v-model="editLabelMap[column.id]"
              :ref="(el) => (editLabelRefsMap[column.id] = el)"
              :class="[
                'bookingTableSettingsModal__form__row__name',
                'm-editable',
                {
                  'm-hidden': column.isHidden,
                },
              ]"
              @keydown.enter="handleSaveLabel(column)"
            />
            <span
              v-if="!editLabelIsActiveMap[column.id]"
              class="bookingTableSettingsModal__form__row__id"
            >
              [{{ column.id }}]
            </span>
          </div>
          <div class="bookingTableSettingsModal__form__row__block">
            <Icon
              :name="
                editLabelIsActiveMap[column.id] ? 'checkmark-circle' : 'pencil'
              "
              color="secondary-500"
              is-clickable
              @click="handleEditClick(column)"
            />
            <Icon
              :name="column.isHidden ? 'eye' : 'eye-closed'"
              :color="
                editLabelIsActiveMap[column.id]
                  ? 'secondary-400'
                  : 'secondary-500'
              "
              :is-clickable="!editLabelIsActiveMap[column.id]"
              @click="handleVisibilityClick(column)"
            />
          </div>
        </div>
      </Draggable>
      <div class="bookingTableSettingsModal__form__actions">
        <Button is-outlined @click="close"> Cancel </Button>
        <Button :is-loading="isSubmitting" @click="handleSubmit"> Save </Button>
      </div>
    </Form>
  </CustomModal>
</template>

<script>
import Draggable from "vuedraggable";

export default {
  name: "BookingsTableSettingsModal",
  components: { Draggable },
  props: {
    modalName: {
      type: String,
      default: "bookingTableSettingsModal",
    },
    value: {
      type: Array,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: {},
      editLabelMap: {},
      editLabelIsActiveMap: {},
      editLabelRefsMap: {},
    };
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    reset() {
      this.innerValue = JSON.parse(JSON.stringify(this.value));
      this.editLabelIsActiveMap = this.innerValue.reduce(
        (prev, column) => ({ ...prev, [column.id]: false }),
        {}
      );
      this.editLabelMap = this.innerValue.reduce(
        (prev, column) => ({ ...prev, [column.id]: column.label }),
        {}
      );
    },
    handleSubmit() {
      this.$emit("submit", this.innerValue);
    },
    handleEditClick(column) {
      if (this.editLabelIsActiveMap[column.id]) {
        this.handleSaveLabel(column);
      } else {
        this.editLabelIsActiveMap[column.id] = true;
        this.editLabelRefsMap[column.id]?.focus();
      }
    },
    handleSaveLabel(column) {
      const value = this.editLabelMap[column.id]?.replace(/\n/, "");
      if (value) {
        this.editLabelIsActiveMap[column.id] = false;
        column.label = value;
        this.editLabelMap[column.id] = value;
      }
    },
    handleVisibilityClick(column) {
      if (!this.editLabelIsActiveMap[column.id]) {
        column.isHidden = !column.isHidden;
      }
    },
  },
};
</script>

<style lang="scss">
.bookingTableSettingsModal {
  padding: 40px 24px;
  overflow: visible;
  &.vm--modal {
    max-width: 800px !important;
    max-height: 95vh;
    overflow: auto;
  }
}
</style>
<style scoped lang="scss">
.bookingTableSettingsModal {
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__title {
    text-align: center;
    margin-bottom: 24px;
  }

  &__form {
    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      padding: 8px 0;
      border-bottom: 1px solid $secondary-400;

      &__block {
        display: flex;
        align-items: center;
        gap: 12px;

        .drag-handler {
          cursor: grab;
        }
      }

      &__name {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $secondary-500;
        border-bottom: 1px solid transparent;

        &.m-editable {
          border-width: 0 0 1px;
          border-bottom-style: dashed;
          border-bottom-color: $primary;
          padding: 0;
        }

        &.m-hidden {
          color: $secondary-400;
        }
      }

      &__id {
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: $secondary-400;
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: 12px;
      flex-wrap: wrap;

      @media (min-width: $media-laptop) {
        flex-direction: row;
      }

      & > * {
        flex: 1;
      }
    }
  }
}
</style>
