<template>
  <button
    @click="handleSelectedView"
    :class="[
      'change-view-button',
      variant === BookingPageViewEnum.TABLE ? 'space-3' : '',
    ]"
  >
    <span
      v-for="item in countItems"
      :key="item"
      :class="[
        'change-view-button__item',
        variant === BookingPageViewEnum.DETAILED
          ? 'm-big'
          : variant === BookingPageViewEnum.COMPACT
          ? 'm-middle'
          : 'm-small',
      ]"
    ></span>
  </button>
</template>

<script>
import { BookingPageViewEnum } from "@/helpers/enums";

export default {
  name: "ChangeViewButton",
  props: {
    variant: {
      type: String,
      default: "table",
    },
  },
  data() {
    return {
      activeView: "",
      BookingPageViewEnum,
    };
  },
  computed: {
    countItems() {
      return this.variant === BookingPageViewEnum.DETAILED
        ? 2
        : this.variant === BookingPageViewEnum.COMPACT
        ? 3
        : 4;
    },
  },
  methods: {
    handleSelectedView() {
      this.activeView = this.variant;
      this.$emit("onSelectView", this.activeView);
    },
  },
};
</script>

<style lang="scss" scoped>
.change-view-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin: 0;
  gap: 0.99px;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  transition: 0.3s ease;
  cursor: pointer;

  &.space-3 {
    gap: 2.8px;
  }

  &.m-active {
    .change-view-button__item {
      background-color: var(--color-primary);
    }
  }

  &:hover .change-view-button__item {
    background-color: var(--color-primary-hover);
  }

  &__item {
    display: block;
    background: rgba(44, 59, 85, 0.2);
    border-radius: 1px;
    transition: 0.3s ease;

    &.m-big {
      width: 20px;
      height: 8px;
    }

    &.m-middle {
      width: 20px;
      height: 5px;
    }

    &.m-small {
      width: 20px;
      height: 2px;
    }
  }
}
</style>
