<template>
  <div class="bookingsPage">
    <div class="bookingsPage__titleRow">
      <div class="bookingsPage__titleWrapper">
        <Title>Bookings</Title>
      </div>
      <BookingSearch
        v-model="searchQuery"
        :field.sync="searchField"
        @search="handleSearch"
      />
      <IconButton
        v-if="selectedView !== BookingPageViewEnum.COMPACT"
        class="bookingsPage__add-btn"
        is-raised
        icon="plus"
        @click="goToCreation"
      >
        Add booking
      </IconButton>
    </div>
    <div class="bookingsPage__filters">
      <div class="bookingsPage__filters-top-wrapper">
        <div class="bookingsPage__filters-left">
          <div class="bookingsPage__rangeButtons">
            <button
              v-for="button in rangeButtons"
              :key="button.value"
              :class="[
                'bookingsPage__rangeButton',
                {
                  'm-active': selectedRange === button.value,
                },
              ]"
              @click="handleRangeButtonClick(button.value)"
            >
              {{ button.name }}
            </button>
            <div
              class="bookingsPage__sort"
              @click="isFiltersShow = !isFiltersShow"
            >
              <div
                :class="[
                  'bookingsPage__sort__icon',
                  { 'm-count': hiddenFiltersCount },
                ]"
                :data-count="hiddenFiltersCount"
              >
                <Icon
                  :size="24"
                  name="filters"
                  :color="isFiltersShow ? 'primary' : 'black'"
                />
              </div>
              <p class="bookingsPage__sort__text">
                {{ `${isFiltersShow ? "Minimize" : "More"} filters` }}
              </p>
            </div>
          </div>
        </div>
        <div class="bookingsPage__changeView">
          <ChangeViewButton
            v-for="view in views"
            :class="{
              'm-active': selectedView === view,
            }"
            :key="view"
            :variant="view"
            @onSelectView="handleSelectView"
          />
        </div>
      </div>
      <div v-if="isFiltersShow" class="bookingsPage__filtersWrapper">
        <Select
          label="Status"
          placeholder="Select status"
          :value="activeFilter"
          :options="filterOptions"
          @input="handleFilterChange"
        />
        <Select
          v-model="selectedProductIdFilter"
          :options="productOptions"
          label="Product"
          placeholder="Select product"
        />
        <button class="bookingsPage__reset" @click="handleResetFilters">
          <Icon
            class="bookingsPage__reset__icon"
            :size="24"
            name="reload"
            color="secondary-500"
          />
          <p class="bookingsPage__reset__text">Reset Filters</p>
        </button>
      </div>
    </div>
    <template v-if="selectedView === BookingPageViewEnum.DETAILED">
      <div v-if="isDetailedLoading" class="bookingsPage__detailedList">
        <loader color="primary" size="l" />
      </div>
      <detailed-view
        v-else-if="detailedListLength"
        :bookings="detailedBookings"
        @booking-click="handleBookingClick($event)"
        @favorite-click="handleFavoriteDetailedClick($event)"
        @create-booking="goToCreation({ preSelectedSlot: $event })"
      />
      <div v-else class="mt-24 empty">
        {{ $t("You haven't created bookings yet") }}
      </div>
      <div v-if="detailedListLength >= 100" class="mt-24 empty">
        Displayed 100 bookings for this query. Refine your search to see more
      </div>
    </template>
    <div
      v-else-if="selectedView === BookingPageViewEnum.COMPACT"
      class="grid-2"
    >
      <Guard permission="bookings.create" v-slot="{ isAvailable }">
        <CreateButton
          v-if="isAvailable"
          @click="goToCreation({ preSelectedProductId: selectedProductId })"
        >
          Create booking
        </CreateButton>
      </Guard>
      <booking-card
        v-for="(booking, index) in bookings"
        :key="booking.id + index"
        :booking="booking"
        :timezone="venue.timezone"
        :break-time="getProduct(booking.productId).breakTime"
        :duration="getProduct(booking.productId).duration"
        :product-name="getProduct(booking.productId).name"
        @click.native="() => handleBookingClick(booking.id)"
        @favorite-clicked="(value) => handleFavoriteClick(booking, value)"
      />
      <infinite-loading
        class="grid-2__full-item"
        :identifier="infiniteId"
        @infinite="infiniteHandler"
      >
        <Loader slot="spinner" class="mx-auto" color="primary" size="m" />
        <div class="empty" slot="no-results">
          {{ $t("You haven't created bookings yet") }}
        </div>
        <div slot="no-more"></div>
      </infinite-loading>
    </div>
    <div v-else>
      <BookingsTable
        :bookings="bookings"
        :columns="tableColumns"
        has-settings-columns
        @sort="handleTableSort"
        @on-row-click="handleBookingClick($event)"
      >
        <template #footer>
          <infinite-loading
            class="grid-2__full-item"
            :identifier="infiniteId"
            @infinite="infiniteHandler"
          >
            <Loader slot="spinner" class="mx-auto" color="primary" size="m" />
            <div class="empty" slot="no-results">
              {{ $t("You haven't created bookings yet") }}
            </div>
            <div slot="no-more"></div>
          </infinite-loading>
        </template>
      </BookingsTable>
    </div>
    <DatePeriodModal
      v-model="dateRange"
      :range-buttons="rangeButtons"
      :selected-range="selectedRange"
      @reset-selected-range="selectedRange = null"
      @range-button-click="handleRangeButtonClick"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import alert from "@/plugins/alert";
import BookingCard from "@/components/bookings/BookingCard";
import {
  BookingFilterEnum,
  BookingPageViewEnum,
  BookingTableSortFieldEnum,
} from "@/helpers/enums";
import { RangeButtonsEnum } from "@/helpers/enums";
import moment from "moment";
import CreateButton from "@/components/common/CreateButton";
import DetailedView from "@/components/detailed-view/DetailedView";
import Guard from "@/components/common/Guard";
import {
  dateRangeOptions,
  DEFAULT_BOOKINGS_TABLE_COLUMNS,
} from "@/helpers/mocks";
import BookingsTable from "@/components/BookingsTable/BookingsTable.vue";
import BookingSearch from "@/components/bookings/BookingSearch";
import ChangeViewButton from "@/components/changeViewButton/ChangeViewButton";
import DatePeriodModal from "@/components/bookings/DatePeriodModal";
import axios from "axios";
import { getBookingsTableColumns } from "@/helpers/bookingsTable";

export default {
  name: "BookingsPage",
  components: {
    BookingSearch,
    Guard,
    DetailedView,
    CreateButton,
    BookingCard,
    BookingsTable,
    ChangeViewButton,
    DatePeriodModal,
  },
  props: {
    selectedDate: {
      type: String,
      default: null,
    },
    isKeepDate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      infiniteId: +new Date(),
      isFiltersShow: false,
      isDetailedLoading: false,
      currentFirstDateSelect: null,
      limit: 9,
      rangeButtons: [...dateRangeOptions],
      views: [
        BookingPageViewEnum.DETAILED,
        BookingPageViewEnum.COMPACT,
        BookingPageViewEnum.TABLE,
      ],
      BookingPageViewEnum,
    };
  },
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
      venueUserJunction: (state) => state.users.venueUserJunction,
      bookings: (state) => state.bookings.list,
      detailedBookings: (state) => state.bookings.detailedList,
      detailedListLength: (state) => state.bookings.detailedListLength,
      activeFilter: (state) => state.bookings.activeFilter,
      selectedProduct: (state) => state.products.selectedProduct,
      products: (state) => state.products.list,
      dateRangeFilter: (state) => state.bookings.dateRangeFilter,
      periodFilter: (state) => state.bookings.periodFilter,
      selectedView: (state) => state.bookings.selectedView,
      activeSortField: (state) => state.bookings.activeSortField,
    }),
    ...mapGetters({
      checkPermission: "users/checkPermission",
    }),
    tableColumns() {
      return getBookingsTableColumns(
        this.venueUserJunction?.bookingsTableColumns ||
          DEFAULT_BOOKINGS_TABLE_COLUMNS,
      );
    },
    hiddenFiltersCount() {
      return [this.activeFilter, this.selectedProductIdFilter].filter(Boolean)
        .length;
    },
    filterOptions() {
      return [
        {
          value: null,
          name: "All",
        },
        this.isUpcomingFilterAvailable() && {
          value: BookingFilterEnum.UPCOMING,
          name: "Upcoming",
        },
        {
          value: BookingFilterEnum.SAVED,
          name: "Saved",
        },
        {
          value: BookingFilterEnum.ABANDONED,
          name: "Abandoned",
        },
        {
          value: BookingFilterEnum.ENDED,
          name: "Ended",
        },
        {
          value: BookingFilterEnum.CANCELED,
          name: "Canceled",
        },
        {
          value: BookingFilterEnum.WARNING,
          name: "Warning",
        },
        {
          value: BookingFilterEnum.PAID,
          name: "Paid",
        },
        {
          value: BookingFilterEnum.WAIT_FOR_PAYMENT,
          name: "Waiting for payments",
        },
        {
          value: BookingFilterEnum.REFUNDED,
          name: "Refunded",
        },
      ].filter(Boolean);
    },
    isAddToSavedAvailable() {
      return this.checkPermission("bookings.save");
    },
    selectedProductId: {
      get() {
        return this.selectedProduct?.id || null;
      },
      set(newValue, oldValue) {
        this.SET_SELECTED_PRODUCT(
          this.products.find((item) => item.id === newValue),
        );
        if (newValue !== oldValue) {
          this.resetBookings();
          this.infiniteId += 1;
        }
      },
    },
    selectedProductIdFilter: {
      get() {
        return this.$store.state.bookings.activeProductFilter;
      },
      set(newValue) {
        const oldValue = this.selectedProductIdFilter;
        this.SET_ACTIVE_PRODUCT_FILTER(newValue);

        if (newValue !== oldValue) {
          this.resetBookings();
          this.infiniteId += 1;
        }
      },
    },
    productOptions() {
      return [
        {
          name: "All",
          value: null,
        },
        ...this.products.map((item) => ({
          name: item.name,
          value: item.id,
        })),
      ];
    },
    dateRange: {
      get() {
        return this.dateRangeFilter;
      },
      set(newValue, oldValue) {
        this.SET_DATE_RANGE_FILTER(newValue);
        if (newValue !== oldValue) {
          this.resetBookings();
          this.infiniteId += 1;
        }
      },
    },
    selectedRange: {
      get() {
        return this.periodFilter;
      },
      set(value) {
        this.SET_PERIOD_FILTER(value);
      },
    },
    searchQuery: {
      get() {
        return this.$store.state.bookings.searchQuery;
      },
      set(newValue) {
        this.$store.commit("bookings/SET_SEARCH_QUERY", newValue);
      },
    },
    searchField: {
      get() {
        return this.$store.state.bookings.searchField;
      },
      set(newValue) {
        this.$store.commit("bookings/SET_SEARCH_FIELD", newValue);
      },
    },
  },
  watch: {
    async selectedDate() {
      if (this.selectedDate) {
        this.SET_DATE_RANGE_FILTER([this.selectedDate]);
        await this.resetBookings();
      }
    },
  },
  async created() {
    if (this.selectedDate) {
      if (this.selectedView === BookingPageViewEnum.COMPACT) {
        this.$store.commit(
          "bookings/SET_SELECTED_VIEW",
          BookingPageViewEnum.DETAILED,
        );
      } else if (
        this.selectedView === BookingPageViewEnum.TABLE &&
        this.activeSortField === BookingTableSortFieldEnum.CREATION_DATE
      ) {
        this.$store.commit(
          "bookings/SET_ACTIVE_SORT_FIELD",
          BookingTableSortFieldEnum.EVENT_DATE,
        );
      }
      if (moment.utc(this.selectedDate).isSame(undefined, "day")) {
        this.handleRangeButtonClick(RangeButtonsEnum.TODAY);
      } else {
        this.handleRangeButtonClick(null);
      }
      this.dateRange = [this.selectedDate];
    }
    await this.fetchProducts();
  },
  mounted() {
    const isSuccessAdded = this.$route.query.isSuccessAdded;

    if (isSuccessAdded) {
      this.$router.replace({ query: null });
    }

    if (
      !this.selectedDate &&
      this.selectedView === BookingPageViewEnum.DETAILED &&
      !this.isKeepDate
    ) {
      this.handleRangeButtonClick(RangeButtonsEnum.TODAY);
    } else {
      this.resetBookings();
    }

    if (isSuccessAdded === "true") {
      alert.open({
        message: this.$t("Booking has been made"),
        variant: "success",
      });
    }
  },
  methods: {
    ...mapActions({
      fetchProducts: "products/fetchProducts",
      fetchBookings: "bookings/fetchBookings",
      resetStoreBookings: "bookings/resetBookings",
      updateBooking: "bookings/updateBooking",
    }),
    ...mapMutations({
      SET_ACTIVE_FILTER: "bookings/SET_ACTIVE_FILTER",
      SET_DATE_RANGE_FILTER: "bookings/SET_DATE_RANGE_FILTER",
      SET_SELECTED_PRODUCT: "products/SET_SELECTED_PRODUCT",
      SET_LAST_DOC: "bookings/SET_LAST_DOC",
      SET_LIST: "bookings/SET_LIST",
      SET_PERIOD_FILTER: "bookings/SET_PERIOD_FILTER",
      SET_ACTIVE_PRODUCT_FILTER: "bookings/SET_ACTIVE_PRODUCT_FILTER",
      SET_SEARCH_QUERY: "bookings/SET_SEARCH_QUERY",
    }),
    handleSelectView(view) {
      if (
        !this.isUpcomingFilterAvailable(view) &&
        this.activeFilter === BookingFilterEnum.UPCOMING
      ) {
        this.SET_ACTIVE_FILTER(null);
      }
      if (view !== BookingPageViewEnum.DETAILED) {
        this.dateRange = [];
        this.currentFirstDateSelect = null;
        this.selectedRange = null;
      } else {
        this.handleRangeButtonClick(RangeButtonsEnum.TODAY);
        this.fetchDetailedBookings();
      }
      this.$store.commit("bookings/SET_SELECTED_VIEW", view);
    },
    getProduct(id) {
      return (
        (id
          ? this.products.find((product) => product.id === id)
          : this.selectedProduct) || {}
      );
    },
    isUpcomingFilterAvailable(viewMaybe) {
      const view = viewMaybe || this.selectedView;
      return (
        view === BookingPageViewEnum.DETAILED ||
        (view === BookingPageViewEnum.TABLE &&
          this.activeSortField === BookingTableSortFieldEnum.EVENT_DATE)
      );
    },
    async resetBookings() {
      await this.resetStoreBookings();

      if (this.selectedView === BookingPageViewEnum.DETAILED) {
        await this.fetchDetailedBookings();
      }
    },
    async fetchDetailedBookings() {
      let isCanceled = false;
      try {
        this.isDetailedLoading = true;
        await this.fetchBookings({
          withPagination: false,
        });
      } catch (err) {
        isCanceled = axios.isCancel(err);
      } finally {
        if (!isCanceled) {
          this.isDetailedLoading = false;
        }
      }
    },
    handleRangeButtonClick(rangeType) {
      if (
        this.selectedRange === rangeType &&
        rangeType !== RangeButtonsEnum.CUSTOM
      ) {
        return;
      }

      const newDate = [];

      if (rangeType) {
        this.selectedRange = rangeType;

        if (rangeType === RangeButtonsEnum.CUSTOM) {
          this.$modal.show("datePeriodModal");
          return;
        }

        if (!this.currentFirstDateSelect) {
          this.currentFirstDateSelect = {
            date: moment().date(),
            month: moment().month(),
            year: moment().year(),
          };
        }

        newDate[1] = this.currentFirstDateSelect;
        let startDate = moment(
          new Date(newDate[1].year, newDate[1].month, newDate[1].date),
        );

        switch (this.selectedRange) {
          case RangeButtonsEnum.WEEK:
            startDate.add(-1, "week");
            break;
          case RangeButtonsEnum.MONTH:
            startDate.add(-1, "month");
            break;
          case RangeButtonsEnum.YEAR:
            startDate.add(-1, "year");
            break;
          case RangeButtonsEnum.THIS_WEEK:
            startDate = moment().startOf("week");
            newDate[1] = {
              year: moment().endOf("week").year(),
              month: moment().endOf("week").month(),
              date: moment().endOf("week").date(),
            };
            break;
          case RangeButtonsEnum.THIS_MONTH:
            startDate = moment().startOf("month");
            newDate[1] = {
              year: moment().endOf("month").year(),
              month: moment().endOf("month").month(),
              date: moment().endOf("month").date(),
            };
            break;
          case RangeButtonsEnum.THIS_YEAR:
            startDate = moment().startOf("year");
            newDate[1] = {
              year: moment().endOf("year").year(),
              month: moment().endOf("year").month(),
              date: moment().endOf("year").date(),
            };
            break;
          case RangeButtonsEnum.YESTERDAY:
            startDate = moment().add(-1, "day");
            delete newDate[1];
            break;
          case RangeButtonsEnum.TODAY:
            startDate = moment();
            delete newDate[1];
            break;
          case RangeButtonsEnum.TOMORROW:
            startDate = moment().add(1, "day");
            delete newDate[1];
            break;
        }

        newDate[0] = {
          year: startDate.year(),
          month: startDate.month(),
          date: startDate.date(),
        };
      }

      this.dateRange = [
        newDate[0] &&
          moment(
            new Date(newDate[0].year, newDate[0].month, newDate[0].date),
          ).format("YYYY-MM-DD"),
        newDate[1] &&
          moment(
            new Date(newDate[1].year, newDate[1].month, newDate[1].date),
          ).format("YYYY-MM-DD"),
      ];
    },
    async handleFilterChange(option) {
      if (this.activeFilter !== option) {
        this.SET_ACTIVE_FILTER(option);
        await this.resetBookings();
        this.infiniteId += 1;
      }
    },
    async infiniteHandler($state) {
      try {
        const options = {
          limit: this.limit,
        };
        const len = await this.fetchBookings(options);
        if (len) {
          $state.loaded();
          if (len < this.limit) {
            $state.complete();
          }
        } else {
          $state.complete();
        }
      } catch (e) {
        $state.complete();
      }
    },
    handleBookingClick(id) {
      this.$router.push({
        name: "BookingDetail",
        params: {
          id,
        },
      });
    },
    goToCreation(params) {
      this.$router.push({
        name: "BookingCreate",
        params,
      });
    },
    async handleFavoriteClick(booking, isSaved) {
      if (this.isAddToSavedAvailable) {
        try {
          await this.updateBooking({
            id: booking.id,
            data: {
              isSaved,
            },
            replaceBooking: true,
          });
          alert.open({
            message: this.$t(isSaved ? "Added to Saved" : "Removed from Saved"),
            variant: "secondary",
          });
        } catch (e) {
          console.log(e);
          alert.open({
            message: this.$t(
              isSaved
                ? "Failed to add to Saved"
                : "Failed to remove from Saved",
            ),
            variant: "danger",
          });
        }
      }
    },
    async handleFavoriteDetailedClick(data) {
      if (this.isAddToSavedAvailable) {
        try {
          await this.updateBooking({
            id: data.bookingId,
            data: {
              isSaved: data.value,
            },
            replaceDetailedBooking: true,
            detailedData: data,
          });
          alert.open({
            message: this.$t(
              data.value ? "Added to Saved" : "Removed from Saved",
            ),
            variant: "secondary",
          });
        } catch (e) {
          console.log(e);
          alert.open({
            message: this.$t(
              data.value
                ? "Failed to add to Saved"
                : "Failed to remove from Saved",
            ),
            variant: "danger",
          });
        }
      }
    },
    handleSearch() {
      this.resetBookings();
      this.infiniteId += 1;
    },
    handleTableSort() {
      if (
        !this.isUpcomingFilterAvailable() &&
        this.activeFilter === BookingFilterEnum.UPCOMING
      ) {
        this.SET_ACTIVE_FILTER(null);
      }
      this.resetBookings();
      this.infiniteId += 1;
    },
    handleResetFilters() {
      this.SET_ACTIVE_FILTER(null);
      this.SET_ACTIVE_PRODUCT_FILTER(null);
      this.SET_DATE_RANGE_FILTER([]);
      this.SET_PERIOD_FILTER(null);
      this.SET_SEARCH_QUERY("");
      this.resetBookings();
      this.infiniteId += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.bookingsPage {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__titleRow {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    gap: 16px;

    @media (max-width: $media-tablet) {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 24px;
    }
  }

  &__titleWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__detailedView {
    margin-left: auto;
    white-space: nowrap;

    @media (max-width: $media-tablet) {
      margin-left: 0;
    }
  }

  &__sort {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 24px;

    &__text {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      color: #384966;
    }

    &__icon {
      user-select: none;
      position: relative;
      height: 24px;
      &.m-count {
        &::before {
          content: attr(data-count);
          position: absolute;
          top: -4ox;
          right: -10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;

          background-color: $primary;
          border-radius: 50%;

          color: $white;
          font-size: 9px;
          font-weight: 700;
        }
      }
    }
  }
  &__reset {
    cursor: pointer;
    height: 67px;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 12px;

    &__text {
      color: $secondary-500;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    &__icon {
      transform: scaleX(-1) rotate(275deg);
    }
  }

  &__titleCount {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: var(--color-primary);
  }

  &__rangeButtons {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    // margin-bottom: 16px;

    // @media (min-width: $media-tablet) {
    //   margin-bottom: 24px;
    // }
  }

  &__add-btn {
    margin-bottom: 0;
    flex-grow: 1;
    flex-shrink: 0;
  }

  &__rangeButton {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 3px 16px;
    background: transparent;
    border: 2px solid #384966;
    color: #384966;
    border-radius: 6px;
    transition: 0.3s;
    cursor: pointer;
    opacity: 0.4;

    &.m-active {
      opacity: 1;
    }
  }

  &__changeView {
    display: flex;
    gap: 4px;
  }

  &__filters {
    display: flex;
    flex-direction: column;

    &-top-wrapper {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      gap: 16px;
      margin-bottom: 16px;

      @media (min-width: $media-tablet) {
        margin-bottom: 24px;
      }
    }

    &-left {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__filtersWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    margin-bottom: 20px;
    align-items: end;

    @media (max-width: $media-laptop - 1) {
      grid-template-columns: 1fr;
    }
  }

  &__detailedList {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1;
  }

  &__detailedMessage {
    font-size: 20px;
    line-height: 28px;
    text-align: center;

    color: $secondary-500;
  }
}
</style>
