<template>
  <div class="detailedViewDatesBlock">
    <div class="detailedViewDatesBlock__dateWrapper">
      {{ formattedDate }}
      <div class="detailedViewDatesBlock__separator" />
    </div>
    <template v-for="(startKey, i) in sortedStartKeys">
      <detailed-view-slots-block
        v-for="(endKey, j) in getSortedEndKeys(dates[startKey])"
        :key="`${i}-${j}`"
        :start="startKey"
        :end="endKey"
        :data="dates[startKey][endKey]"
        @booking-click="$emit('booking-click', $event)"
        @favorite-click="
          $emit('favorite-click', {
            ...$event,
            endKey,
            startKey,
          })
        "
        @create-booking="
          $emit('create-booking', { ...$event, start: startKey, end: endKey })
        "
      />
    </template>
  </div>
</template>
<script>
import DetailedViewSlotsBlock from "./DetailedViewSlotsBlock";
import moment from "moment";

export default {
  name: "DetailedViewDatesBlock",
  components: { DetailedViewSlotsBlock },
  props: {
    date: {
      type: String,
      required: true,
    },
    dates: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sortedStartKeys() {
      return Object.keys(this.dates).sort((a, b) => a - b);
    },
    formattedDate() {
      return moment(this.date, "YYYY-MM-DD").format("MMM D, YYYY");
    },
  },
  methods: {
    getSortedEndKeys(dates) {
      return Object.keys(dates).sort((a, b) => a - b);
    },
  },
};
</script>
<style lang="scss">
.detailedViewDatesBlock {
  &:not(:first-child) {
    margin-top: 32px;
  }

  &__dateWrapper {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $secondary-500;
    display: flex;
    align-items: flex-end;
    position: sticky;
    top: 0;
    background: $background-color;
    padding: 12px 44px 0;
    width: calc(100% + 88px);
    margin-left: -44px;
    z-index: 3;
  }

  &__separator {
    flex: 1;
    border-bottom: 1px solid $secondary-500;
    margin-left: 8px;
    margin-bottom: 6px;
  }
}
</style>
