<template>
  <div class="detailedView">
    <detailed-view-dates-block
      v-for="(key, index) in sortedBookingsKeys"
      :key="index"
      :date="key"
      :dates="bookings[key]"
      @booking-click="$emit('booking-click', $event)"
      @favorite-click="
        $emit('favorite-click', {
          ...$event,
          date: key,
        })
      "
      @create-booking="$emit('create-booking', { ...$event, date: key })"
    />
  </div>
</template>
<script>
import DetailedViewDatesBlock from "./DetailedViewDatesBlock";
export default {
  name: "DetailedView",
  components: { DetailedViewDatesBlock },
  props: {
    bookings: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sortedBookingsKeys() {
      return Object.keys(this.bookings).sort((a, b) => a.localeCompare(b));
    },
  },
};
</script>
<style lang="scss">
.detailedView {
  width: 100%;
}
</style>
