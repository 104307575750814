<template>
  <div class="booking-search">
    <div class="booking-search__container">
      <input
        v-model="innerValue"
        class="booking-search__input"
        v-mask="selectedOption.mask"
        @keydown.enter="$emit('search')"
      />
      <div class="booking-search__append">
        <div class="booking-search__append__line" />
        <Icon name="search" color="secondary-500" />
        <Dropdown
          :value="innerField"
          :options="searchOptions"
          @select="innerField = $event"
        />
      </div>
    </div>
    <Button
      :is-disabled="isSearchDisabled"
      :is-block="['xs', 'sm'].includes($mq)"
      class="booking-search__button"
      @click="$emit('search')"
    >
      Search
    </Button>
  </div>
</template>
<script>
import Dropdown from "@/components/common/Dropdown";
import { BookingSearchFieldOptions } from "@/helpers/const";

export default {
  name: "BookingSearch",
  components: { Dropdown },
  props: {
    field: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchOptions: [...BookingSearchFieldOptions],
    };
  },

  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    innerField: {
      get() {
        return this.field;
      },
      set(option) {
        this.$emit("update:field", option.value);
      },
    },
    selectedOption() {
      return this.searchOptions.find(
        (option) => option.value === this.innerField
      );
    },
    isSearchDisabled() {
      return (
        !this.selectedOption ||
        (this.innerValue.length > 0 &&
          this.innerValue.length < this.selectedOption.min)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.booking-search {
  display: flex;
  gap: 20px;
  width: 100%;

  @media (max-width: $media-tablet) {
    order: 3;
    display: grid;
    grid-template-columns: 1fr;
  }

  &__container {
    position: relative;
    flex: 1;
  }

  &__input {
    background: $white;
    border-radius: 6px;
    border: 2px solid $secondary-400;
    padding: 16px 225px 16px 20px;
    width: 100%;
    height: 100%;
  }

  &__append {
    position: absolute;
    top: 0;
    right: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 15px;

    &__line {
      width: 1px;
      height: 24px;
      background: $secondary-400;
    }
  }

  &__button {
    min-width: 150px;
    width: 150px;
    height: 58px;
  }
}
</style>
