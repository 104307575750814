var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['booking-card', `m-${_vm.theme}`]},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"header"},[_c('span',{staticClass:"title m-overflowed"},[_vm._v(" "+_vm._s(_vm.booking.reservationInfo.firstName)+" "+_vm._s(_vm.booking.reservationInfo.lastName)+" ")]),(_vm.isDetailed)?_c('div',{staticClass:"booking-card__people"},[_c('Icon',{staticClass:"booking-card__peopleIcon",attrs:{"name":"people","color":"secondary-400"}}),_vm._v(" "+_vm._s(_vm.personsText)+" ")],1):(_vm.productName)?_c('div',{staticClass:"booking-card__people m-overflowed"},[_vm._v(" "+_vm._s(_vm.productName)+" ")]):_vm._e(),(_vm.booking.isWarning)?_c('div',{staticClass:"new-label booking-card__time"},[_vm._v(" WARNING ")]):(_vm.booking.isWalkIn)?_c('div',{staticClass:"new-label booking-card__time"},[_vm._v(" WALK-IN ")]):(_vm.formattedTimeAgo === 'new')?_c('div',{staticClass:"new-label booking-card__time"},[_vm._v(" NEW ")]):_c('span',{staticClass:"secondary-text booking-card__time"},[_vm._v(" "+_vm._s(_vm.formattedTimeAgo)+" ")])]),(_vm.booking.reservationInfo && _vm.booking.reservationInfo.note)?_c('div',{staticClass:"booking-card__note"},[_vm._v(" "+_vm._s(_vm.booking.reservationInfo.note)+" ")]):_vm._e(),_c('div',{staticClass:"booking-card__commentWrapper"},[(_vm.isDetailed)?_c('div',[_vm._v(" "+_vm._s(_vm.booking.reservationInfo.phone)+" ")]):_c('div',{class:[
          'datetime',
          { 'm-primary': _vm.calendarIconColor === 'primary' },
        ]},[_c('Icon',{attrs:{"name":"calendar","color":_vm.calendarIconColor}}),_c('span',[_vm._v(" "+_vm._s(_vm.formattedDate)+" / "),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formattedTime))])])],1),_c('Guard',{attrs:{"permission":"bookings.changePresenceStatus"},scopedSlots:_vm._u([{key:"default",fn:function({ isAvailable }){return [_c('BookingStatus',{attrs:{"is-disabled":!isAvailable,"value":_vm.booking.presenceStatus
              ? _vm.booking.presenceStatus
              : _vm.isStartBookingTime
              ? _vm.PresenceStatusEnum.NO
              : null},on:{"change":_vm.handleChangePresenceStatus}})]}}])})],1),_c('div',{staticClass:"booking-card__reg-code"},[_vm._v(" Reg. code: "+_vm._s(_vm.booking.registrationCode)+" ")])]),_c('div',{staticClass:"statuses-row"},[(_vm.isWaitingForPayment)?_c('span',{class:['secondary-text']},[_vm._v(" "+_vm._s(_vm.$t("Waiting for payment"))+"... ")]):[_vm._l((_vm.statuses),function(status,index){return [(index !== 0)?_c('div',{key:`statusDivider-${index}`,staticClass:"statuses-row__divider"}):_vm._e(),_c('span',{key:`statusLabel-${index}`,class:status.classes},[_vm._v(" "+_vm._s(status.label)+" ")])]})],_c('div',{staticClass:"icon-actions"},[_c('Guard',{attrs:{"permission":"signatures.create"},scopedSlots:_vm._u([{key:"default",fn:function({ isAvailable }){return [(isAvailable && _vm.booking.activeWaiver)?_c('Icon',{attrs:{"name":"laptop-pencil","color":"secondary-400","is-clickable":""},on:{"click":function($event){$event.stopPropagation();return _vm.goToSigningWaiver.apply(null, arguments)}}}):_vm._e()]}}])}),(_vm.booking.isSaved)?_c('Icon',{attrs:{"name":"favorite-filled","color":"primary","is-clickable":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('favorite-clicked', false)}}}):_c('Icon',{attrs:{"name":"favorite","color":"secondary-400","is-clickable":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('favorite-clicked', true)}}})],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }