var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'dropdown',
    `m-${_vm.currentVariant ?? _vm.variant}`,
    _vm.isBottomSectionToggled ? 'dropdown-shown' : '',
  ],on:{"click":_vm.toggleDropdown}},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.placeholderText && !_vm.selectedOption ? _vm.placeholderText : _vm.selectedOption.name))]),_c('span',{class:[
      'dropdown__icon',
      _vm.isBottomSectionToggled ? 'dropdown__icon--active' : '',
    ]},[_c('Icon',{attrs:{"name":"chevron-down","size":16,"color":_vm.currentVariant === 'default' ? 'secondary-500' : 'white'}})],1),(_vm.isBottomSectionToggled)?_c('ul',{class:[
      'options',
      `m-${_vm.currentVariant ?? _vm.variant}`,
      _vm.isBottomSectionToggled ? 'options--active' : '',
    ]},_vm._l((_vm.configOptions),function(option,idx){return _c('li',{key:idx,class:['option', `m-${option.variant}`],on:{"click":function($event){return _vm.setCurrentSelectedOption($event, option)}}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }