<template>
  <div class="detailedViewSlotsBlock">
    <div class="detailedViewSlotsBlock__header">
      <div class="detailedViewSlotsBlock__productWrapper">
        {{ data.productTitle }} / {{ duration }}
        <Icon
          class="detailedViewSlotsBlock__clock"
          :size="16"
          name="clock"
          color="secondary-500"
        />
      </div>
      <div class="detailedViewSlotsBlock__timeWrapper">
        <div class="detailedViewSlotsBlock__timeInner">
          <span class="detailedViewSlotsBlock__time">{{ formattedTime }}</span>
          <span class="detailedViewSlotsBlock__booked"
            >Booked {{ data.booked }} / {{ data.max }}</span
          >
          <div class="detailedViewSlotsBlock__separator" />
        </div>
        <Guard permission="bookings.create" v-slot="{ isAvailable }">
          <icon-button
            v-if="isAvailable"
            class="detailedViewSlotsBlock__buttonCreate"
            icon="plus"
            @click="$emit('create-booking', { productId: data.productId })"
          />
        </Guard>
      </div>
    </div>
    <div class="detailedViewSlotsBlock__list grid-2">
      <booking-card
        v-for="(booking, index) in data.bookings"
        :key="booking.id + index"
        :booking="booking"
        :timezone="venue.timezone"
        :break-time="getProduct(booking.productId).breakTime"
        :duration="getProduct(booking.productId).duration"
        is-detailed
        @click.native="$emit('booking-click', booking.id)"
        @favorite-clicked="
          $emit('favorite-click', { bookingId: booking.id, value: $event })
        "
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import BookingCard from "@/components/bookings/BookingCard";
import { getHourName, getSlotFromMinutes } from "@/helpers/utils";
import Guard from "@/components/common/Guard";

export default {
  name: "DetailedViewSlotsBlock",
  components: { Guard, BookingCard },
  props: {
    start: {
      type: [Number, String],
      required: true,
    },
    end: {
      type: [Number, String],
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
      product: (state) => state.products.selectedProduct,
      products: (state) => state.products.list,
    }),
    duration() {
      return this.end - this.start;
    },
    formattedTime() {
      return `${getHourName(
        getSlotFromMinutes(this.start).hours,
        getSlotFromMinutes(this.start).minutes
      )} - ${getHourName(
        getSlotFromMinutes(this.end).hours,
        getSlotFromMinutes(this.end).minutes
      )}`;
    },
  },
  methods: {
    getProduct(id) {
      return this.selectedProduct
        ? this.selectedProduct
        : this.products.find((product) => product.id === id);
    },
  },
};
</script>
<style lang="scss">
.detailedViewSlotsBlock {
  &:not(:last-child) {
    .detailedViewSlotsBlock__list {
      padding-bottom: 24px;
    }
  }

  &__header {
    position: sticky;
    top: 36px;
    background: $background-color;
    padding: 12px 44px 12px;
    width: calc(100% + 88px);
    margin-left: -44px;
    z-index: 2;
  }

  &__productWrapper {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    color: $secondary-500;
    text-transform: uppercase;
  }

  &__list {
    margin-top: 12px;
  }

  &__timeWrapper {
    display: flex;
    align-items: center;
  }

  &__timeInner {
    display: flex;
    align-items: flex-end;
    flex: 1;
  }

  &__time {
    font-size: 20px;
    line-height: 16px;
    font-weight: 500;
    color: $primary;
    margin-right: 6px;
    text-transform: uppercase;
    white-space: nowrap;

    @media (min-width: $media-laptop) {
      font-size: 24px;
      line-height: 20px;
    }
  }

  &__booked {
    font-weight: 300;
    font-size: 10px;
    line-height: 1;
    color: $secondary-500;
    white-space: nowrap;
  }

  &__separator {
    flex: 1;
    border-bottom: 1px dashed rgba(56, 73, 102, 0.5);
    margin: 0 16px 4px;
  }

  &__clock {
    margin-left: 4px;
  }
}
</style>
